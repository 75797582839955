import {
  FunctionComponent,
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useActor, useInterpret } from '@xstate/react';
import { assign, InterpreterFrom } from 'xstate';
import isEqual from 'lodash.isequal';
import {
  Application,
  DisclosuresAttribute,
  Offer,
  OrderItem,
  PaymentTypes,
  isLocalizedString,
  Locale,
  APPLICATION_STATUS,
  MerchantPaymentProduct,
  Order,
  PaymentAgreementDocument,
  IDVerificationEvaluationResponse,
  FilteredApplication,
  OptedOut,
} from '@ads-bread/shared/bread/codecs';
import {
  APPLICATION_STATUS_CODES,
  ID_VERIFICATION_STATUS_CODES,
  NamespacePath,
  NAMESPACE_MAP,
  hasValidOffers,
  validateOfferProductTypes,
  filterApplicationCapacity,
  usd,
} from '@ads-bread/shared/bread/util';
import { isBefore, parseISO } from 'date-fns';
import {
  ApplicationCheckoutOptions,
  applicationMachine,
  ApplicationMachineContext,
  ApplicationPrepareCheckoutOptions,
  ReCreateApplicationOptions,
  SendEvaluateIDVerificationImagesOptions,
  ApplicationServiceErrorData,
} from '../lib/machines/applicationMachine';
import {
  setApplication as setApplicationAnalytics,
  setSelectedOffer as setSelectedOfferAnalytics,
} from '../lib/analytics';
import { FCWithChildren } from '../lib/types';
import {
  FetchHandlerWrapper,
  HandlerWrapper,
  toErrorResponse,
  toResultResponse,
} from '../lib/handlers/base';
import { getApplication } from '../lib/handlers/get-application';
import { createApplication } from '../lib/handlers/create-application';
import { getLatestApplication } from '../lib/handlers/get-latest-application';
import { pollVirtualCardIssuance } from '../lib/handlers/virtual-card-issuance';
import { useFetch } from '../lib/hooks/apiFetch';
import {
  APPLICATION_ID_VERIFICATION_STATUS_CODES,
  isApproved,
  isIneligibleAddress,
  needsFullIIN,
  needsIDVerification,
} from '../lib/applicationStatus';
import { totalPriceIsCovered } from '../lib/application';
import { orderEqual } from '../lib/order';
import { applicationCheckout } from '../lib/handlers/application-checkout';
import { applicationPrepareCheckout } from '../lib/handlers/application-prepare-checkout';
import {
  evaluateIDVerificationImages,
  uploadIDVerificationImage,
} from '../lib/handlers/id-verification';
import { hasFailed, needsManualReview } from '../lib/fraudResponses';
import { getPaymentMethod } from '../lib/handlers/get-payment-method';
import { groupOffersByType } from '../lib/offers';
import { getMaxCapacities } from '../lib/capacity';
import { logger } from '../lib/logger';
import { useNamespace } from '../lib/hooks/useNamespace';
import {
  useCartID,
  useLocationID,
  useMerchantDetails,
  useMerchantID,
  useMerchantOrigin,
  useMerchantPaymentProducts,
  useOrder,
  useProgramValues,
  useSDKCallbacks,
  useVirtualCard,
} from './XPropsContext';
import { useLocale } from './IntlContext';
import { useAuthentication } from './AuthenticationContext';
import { useBuyerMachine } from './BuyerMachineContext';
import { useAppConfig } from './AppConfigContext';
import { useAppData } from './AppDataContext';
import { useFeatureFlags } from './FeatureFlagsContext';

const ZERO_UUID = '00000000-0000-0000-0000-000000000000';

export const PARTIAL_APPROVED_STATUSES = [
  APPLICATION_STATUS_CODES.DecisionApprovedApprovedPartial,
  APPLICATION_STATUS_CODES.DecisionApprovedApprovedContingent,
];

const APPLICATION_STATUS_CODE_ERROR_MAP = Object.entries(
  APPLICATION_STATUS_CODES
).reduce((acc, [key, val]): Record<string, string> => {
  return {
    ...acc,
    [val]: key,
  };
}, {} as Record<string, string>);

export type ApplicationService = InterpreterFrom<typeof applicationMachine>;

export const ApplicationContext = createContext<ApplicationService | null>(
  null
);

export type ApplicationProviderProps = {
  context?: Partial<ApplicationMachineContext>;
};

export const ApplicationMachineProvider: FCWithChildren<ApplicationProviderProps> =
  ({ children, context = {} }) => {
    const { state: authState } = useAuthentication();
    const { state: buyerState } = useBuyerMachine();
    const {
      setPaymentMethodID,
      setPaymentMethods,
      setEstimatedSpend,
      setVirtualCardIssuance,
    } = useAppData();
    const { order } = useOrder();
    const { enableIDVerification } = useAppConfig();
    const apiFetch = useFetch();
    const locationID = useLocationID();
    const { merchantID } = useMerchantID();
    const cartID = useCartID();
    const { merchantOrigin } = useMerchantOrigin();
    const program = useProgramValues();
    const { locale } = useLocale();
    const { merchantPaymentProducts } = useMerchantPaymentProducts();
    const { canViewCapacity } = useMerchantDetails();
    const { onApproved, onCheckout } = useSDKCallbacks();
    const { isVirtualCard } = useVirtualCard();
    const lastOrderRef = useRef<Order>();
    const namespace = useNamespace();
    const { enableAlloyJourney } = useFeatureFlags();

    const initialContext: ApplicationMachineContext = {
      application: context.application || null,
      selectedOffer: context.selectedOffer || null,
      paymentAgreementDocument: context.paymentAgreementDocument || null,
      idVerification: {
        frontImageData: context.idVerification?.frontImageData || null,
        backImageData: context.idVerification?.backImageData || null,
        isIDVerified: context.idVerification?.isIDVerified || false,
      },
    };

    const applicationService = useInterpret(applicationMachine, {
      context: initialContext,
      services: {
        fetchApplication: async (_, event) => {
          const { applicationID, resolve, reject } = event;
          try {
            const applicationRes = await getApplication(
              applicationID,
              apiFetch
            );
            return { applicationRes, resolve };
          } catch (error) {
            throw { error, reject };
          }
        },
        fetchLatestApplication: async () => {
          const applicationRes = await getLatestApplication(apiFetch);
          return {
            applicationRes: toResultResponse<Application | null>(
              applicationRes
            ),
          };
        },
        prepareInStoreApplication: async (ctx) => {
          const app = ctx.application;

          if (!app) {
            throw new Error('Missing context while preparing application!');
          }

          const offers = validateOfferProductTypes(
            app.offers || [],
            merchantPaymentProducts
          );

          const applicationResult: Application = {
            ...app,
            offers,
          };

          if (applicationResult.paymentMethodID) {
            const paymentMethod = await getPaymentMethod(
              apiFetch,
              applicationResult.paymentMethodID,
              app.buyerID
            );
            setPaymentMethodID(paymentMethod.id);
            setPaymentMethods([paymentMethod]);
          }

          const approvedPaymentProductIDs = offers.map(
            (offer) => offer.paymentProduct.id
          );

          const approvedPaymentProducts = merchantPaymentProducts?.filter(
            (pp) => approvedPaymentProductIDs.includes(pp.paymentProduct.id)
          );

          const paymentAgreementsByType = groupOffersByType(offers);

          const {
            INSTALLMENTS: installmentsMaxCapacity,
            SPLITPAY: splitPayMaxCapacity,
          } = getMaxCapacities(
            paymentAgreementsByType,
            approvedPaymentProducts
          );

          setEstimatedSpend({
            INSTALLMENTS: installmentsMaxCapacity.value / 100,
            SPLITPAY: splitPayMaxCapacity.value / 100,
          });

          const applicationRes =
            toResultResponse<Application>(applicationResult);

          return { applicationRes };
        },
        prepareVirtualCardIssuance: async (ctx) => {
          const app = ctx.application;
          if (!app) {
            throw new Error(
              'Cannot prepare virtual issuance without an application!'
            );
          }
          const issuanceResponse = await pollVirtualCardIssuance(
            `APPLICATION.${app.id}`,
            apiFetch
          );

          if (
            issuanceResponse.error ||
            issuanceResponse.result.issuance.status !== 'ISSUED' ||
            issuanceResponse.result.card?.status === 'FAILED'
          ) {
            throw new Error(
              'Virtual Card Error: Error obtaining virtual card issuance.'
            );
          }

          setVirtualCardIssuance(issuanceResponse.result);

          return { issuanceResponse };
        },
        createApplication: async () => {
          if (!order) {
            throw new Error('Cannot create an application without an order!');
          }

          const disclosures: DisclosuresAttribute =
            program.policies.application.tof.disclosures?.map((disclosure) => ({
              type: disclosure.type,
            })) || [];

          const extensions: Application['extensions'] = [
            ...(locationID && locationID !== ZERO_UUID
              ? [{ key: 'locationID', value: locationID }]
              : []),
            ...(cartID ? [{ key: 'cartID', value: cartID }] : []),
          ];

          const { items } = order;

          const applicationRes = await createApplication(
            {
              ...order,
              items: items ? stringifyShippingDescription(items, locale) : [],
            },
            extensions,
            disclosures,
            merchantOrigin,
            apiFetch
          );
          return { applicationRes };
        },
        reCreateInStoreContingentApplication: async (_, event) => {
          const app = event.data.applicationRes.result;

          if (!app) {
            throw new Error(
              'Cannot recreate a contingent application without an existing application!'
            );
          }

          const updatedOrder: Order = {
            items: [],
            subTotal: app.capacity,
            totalDiscounts: usd(0),
            totalPrice: app.capacity,
            totalShipping: usd(0),
            totalTax: usd(0),
          };

          const { items } = updatedOrder;

          const disclosures: DisclosuresAttribute =
            program.policies.application.tof.disclosures?.map((disclosure) => ({
              type: disclosure.type,
            })) || [];

          const extensions: Application['extensions'] = [
            ...(locationID && locationID !== ZERO_UUID
              ? [{ key: 'locationID', value: locationID }]
              : []),
            ...(cartID ? [{ key: 'cartID', value: cartID }] : []),
          ];

          const applicationRes = await createApplication(
            {
              ...updatedOrder,
              items: items ? stringifyShippingDescription(items, locale) : [],
            },
            extensions,
            disclosures,
            merchantOrigin,
            apiFetch
          );
          return { applicationRes };
        },
        reCreateApplication: async (_, event) => {
          const { resolve, reject, options } = event;

          try {
            const { order: optionsOrder } = options;
            const requestOrder = optionsOrder || order;

            if (!requestOrder) {
              throw new Error('Cannot create an application without an order!');
            }

            const disclosures: DisclosuresAttribute =
              program.policies.application.tof.disclosures?.map(
                (disclosure) => ({
                  type: disclosure.type,
                })
              ) || [];

            const extensions: Application['extensions'] = [
              ...(locationID && locationID !== ZERO_UUID
                ? [{ key: 'locationID', value: locationID }]
                : []),
              ...(cartID ? [{ key: 'cartID', value: cartID }] : []),
            ];

            const { items } = requestOrder;

            const applicationRes = await createApplication(
              {
                ...requestOrder,
                items: items ? stringifyShippingDescription(items, locale) : [],
              },
              extensions,
              disclosures,
              merchantOrigin,
              apiFetch
            );
            return { applicationRes, resolve };
          } catch (error) {
            throw { error, reject };
          }
        },
        applicationCheckout: async (ctx, event) => {
          const { resolve, reject, options } = event;
          try {
            const { application, paymentAgreementDocument, selectedOffer } =
              ctx;
            const {
              buyer,
              shippingContactID,
              iovationBlackbox,
              paymentMethodID,
              isSplitPay,
              neuroIdTokens,
            } = options;
            const { pickupInformation } = order;

            if (!application || !selectedOffer || !paymentAgreementDocument) {
              throw new Error(
                'Application and offer required for application checkout!'
              );
            }

            const applicationRes = await applicationCheckout(
              {
                application: {
                  ...application,
                  order: {
                    ...application.order,
                    ...(pickupInformation && {
                      pickupInformation: {
                        ...pickupInformation,
                        name: pickupInformation?.name || buyer?.identity.name,
                        email:
                          pickupInformation?.email || buyer?.identity.email,
                        phone:
                          pickupInformation?.phone || buyer?.identity.phone,
                      },
                    }),
                  },
                },
                shippingContactID,
                paymentAgreementDocument,
                paymentAgreementID: selectedOffer.paymentAgreement.id,
                iovationBlackbox,
                paymentMethodID,
                isSplitPay,
                neuroIdTokens,
              },
              apiFetch
            );

            return { applicationRes, resolve };
          } catch (error) {
            throw { error, reject };
          }
        },
        applicationPrepareCheckout: async (ctx, event) => {
          const { resolve, reject, options } = event;
          try {
            const { application, paymentAgreementDocument, selectedOffer } =
              ctx;
            const {
              iovationBlackbox,
              paymentMethodID,
              isSplitPay,
              neuroIdTokens,
            } = options;

            if (!application || !selectedOffer || !paymentAgreementDocument) {
              throw new Error(
                'Application and offer required for application checkout!'
              );
            }

            const applicationRes = await applicationPrepareCheckout(
              {
                application,
                paymentAgreementDocument,
                iovationBlackbox,
                isSplitPay,
                paymentMethodID,
                paymentAgreementID: selectedOffer.paymentAgreement.id,
                neuroIdTokens,
              },
              apiFetch
            );

            const appResult = applicationRes.result;

            // Make sure the offers for new estimated spend order values are valid
            if (appResult) {
              const validatedOffers = validateOfferProductTypes(
                appResult?.offers || [],
                merchantPaymentProducts
              );

              const applicationResWithValidatedOffers =
                toResultResponse<Application>({
                  ...appResult,
                  offers: validatedOffers,
                });
              return {
                applicationRes: applicationResWithValidatedOffers,
                resolve,
              };
            }

            return { applicationRes, resolve };
          } catch (error) {
            throw { error, reject };
          }
        },
        evaluateIDVerificationImages: async (ctx, event) => {
          const { resolve, reject, options } = event;
          try {
            const { buyer } = options;
            const {
              application,
              selectedOffer,
              idVerification: { frontImageData, backImageData },
            } = ctx;

            if (
              !application ||
              !selectedOffer ||
              !frontImageData ||
              !backImageData ||
              !buyer?.id
            ) {
              throw new Error(
                'Cannot evaluate ID Verification images without required data!'
              );
            }

            const uploadFrontResponse = await uploadIDVerificationImage(
              apiFetch,
              buyer.id,
              frontImageData
            );
            if (
              !uploadFrontResponse.result?.imageID &&
              uploadFrontResponse.error
            ) {
              return {
                evaluateIDResponse: toErrorResponse(uploadFrontResponse.error),
                resolve,
              };
            } else if (
              !uploadFrontResponse.result?.imageID &&
              !uploadFrontResponse.error
            ) {
              throw new Error('Error uploading ID image!');
            }

            const uploadBackResponse = await uploadIDVerificationImage(
              apiFetch,
              buyer.id,
              backImageData
            );
            if (
              !uploadBackResponse?.result?.imageID &&
              uploadBackResponse.error
            ) {
              return { evaluateIDResponse: uploadBackResponse, resolve };
            } else if (
              !uploadBackResponse?.result?.imageID &&
              !uploadBackResponse.error
            ) {
              throw new Error('Error uploading ID image!');
            }

            const evaluateIDResponse = await evaluateIDVerificationImages(
              apiFetch,
              application.id,
              getOriginFromStatus(application.statusCodes || []),
              uploadFrontResponse.result.imageID,
              uploadBackResponse.result.imageID
            );

            return { evaluateIDResponse, resolve };
          } catch (error) {
            throw { error, reject };
          }
        },
        getErrorStatusCodeFromApplication: (_, e) => {
          return new Promise((resolve) => {
            const application = e.data.applicationRes.result;
            const statusCodes = application?.statusCodes || [];
            const statusCode =
              statusCodes.find(
                (domainReason) =>
                  APPLICATION_STATUS_CODE_ERROR_MAP[domainReason]
              ) || '';
            resolve({ statusCode });
          });
        },
      },
      actions: {
        assignApplication: assign({
          application: (_, event) => {
            return event.data.applicationRes?.result || null;
          },
        }),
        assignApplicationWithAppendedStatusCodes: assign({
          application: (ctx, event) => {
            const applicationResult = event.data.applicationRes.result;
            const { application } = ctx;

            if (!applicationResult) {
              return null;
            }

            return {
              ...applicationResult,
              statusCodes: [
                ...(application?.statusCodes || []),
                ...(applicationResult?.statusCodes || []),
              ],
            };
          },
        }),
        assignSelectedOffer: assign({
          selectedOffer: (_, event) => {
            return event.selectedOffer;
          },
        }),
        assignPaymentAgreementDocument: assign({
          paymentAgreementDocument: (_, event) => {
            return event.paymentAgreementDocument;
          },
        }),
        assignIDVerificationFrontImageData: assign({
          idVerification: (ctx, event) => {
            return {
              ...ctx.idVerification,
              frontImageData: event.frontImageData,
            };
          },
        }),
        assignIDVerificationBackImageData: assign({
          idVerification: (ctx, event) => {
            return {
              ...ctx.idVerification,
              backImageData: event.backImageData,
            };
          },
        }),
        assignIDVerificationIsIDVerified: assign({
          idVerification: (ctx) => {
            return {
              ...ctx.idVerification,
              isIDVerified: true,
            };
          },
        }),
        resetContext: assign({
          ...initialContext,
        }),
        handleApplicationAnalytics: (_, event) => {
          const app = event.data.applicationRes.result;
          if (app) {
            setApplicationAnalytics(app);
          }
        },
        handleSelectedOfferAnalytics: (_, event) => {
          setSelectedOfferAnalytics(event.selectedOffer);
        },
        handleApplicationDone: (_, event) => {
          event.data.resolve(event.data.applicationRes);
        },
        handleIDVerificationDone: (_, event) => {
          event.data.resolve(event.data.evaluateIDResponse);
        },
        handleApplicationServiceError: (_, event) => {
          const errorData = event.data as ApplicationServiceErrorData;
          errorData.reject(errorData.error);
        },
        handleOnApproved: (_, event) => {
          const application = event.data.applicationRes.result;
          const storedBuyer = buyerState?.context?.buyer;

          if (application) {
            const approvedData = filterApplicationCapacity(
              application,
              storedBuyer,
              canViewCapacity
            );
            onApproved?.(
              isOptedOut(approvedData)
                ? (({ buyerID, ...filteredApprovedData }) =>
                    filteredApprovedData)(approvedData)
                : approvedData
            );
          }
        },
        handleOnCheckout: (_, event) => {
          const application = event.data.applicationRes.result;
          const storedBuyer = buyerState?.context?.buyer;

          /**
           * Report if not application was passed in event. Remove after confirming
           * in production. This should not be possible
           */
          if (!application) {
            logger.error(
              { buyerID: storedBuyer?.id },
              'handleOnCheckout called without application'
            );
            return;
          }

          /**
           * Report if integrator onCheckout event is missing. This should not be possible unless
           * this is a carts experience
           */
          if (!onCheckout) {
            logger.error(
              { applicationID: application.id, buyerID: storedBuyer?.id },
              'handleOnCheckout called without onCheckout callback'
            );
            return;
          }

          if (application) {
            const checkoutData = filterApplicationCapacity(
              application,
              storedBuyer,
              canViewCapacity
            );
            logger.info(
              { applicationID: checkoutData.id, buyerID: checkoutData.buyerID },
              'handleOnCheckout called'
            );
            onCheckout?.(
              isOptedOut(checkoutData)
                ? (({ buyerID, ...filteredCheckoutData }) =>
                    filteredCheckoutData)(checkoutData)
                : checkoutData
            );
          }
        },
        logError: (ctx, event) => {
          const err =
            event.data instanceof Error ? event.data : { message: event.data };
          logger.error(
            {
              err,
              merchantID,
              applicationID: ctx.application?.id,
              buyerID: ctx.application?.buyerID,
            },
            `Error in ApplicationMachineContext: ${event.type}`
          );
        },
      },
      guards: {
        isApprovedContingentInStore: (_, e) => {
          const application = e.data.applicationRes.result;

          if (!application) {
            return false;
          }

          const isApprovedContingent = application.statusCodes?.some(
            (statusCode) =>
              statusCode ===
              APPLICATION_STATUS_CODES.DecisionApprovedApprovedContingent
          );

          return !!(
            namespace === NAMESPACE_MAP.InStore && isApprovedContingent
          );
        },
        isApproved: (_, e) => {
          const application = e.data.applicationRes.result;
          if (!application) {
            return false;
          }
          return isApproved(application);
        },
        isCheckoutCompleted: (_, e) => {
          const status = e.data.applicationRes.result?.status;
          return status === APPLICATION_STATUS.CHECKOUT_COMPLETED;
        },
        isCheckoutPrepared: (_, e) => {
          const status = e.data.applicationRes.result?.status;
          return status === APPLICATION_STATUS.CHECKOUT_PREPARED;
        },
        isInstallmentOfferSelected: (_, e) => {
          return (
            e.selectedOffer.paymentProduct.type === PaymentTypes.INSTALLMENTS
          );
        },
        isSplitPayOfferSelected: (_, e) => {
          return e.selectedOffer.paymentProduct.type === PaymentTypes.SPLITPAY;
        },
        isBuyerReadyAndComplete: () => {
          return buyerState.matches('ready.complete');
        },
        isUnexpiredInStoreApplication: (_, e) => {
          const app = e.data.applicationRes.result;

          if (!app) {
            return false;
          }

          return isUnexpiredInStoreApplication(namespace, app);
        },
        isVirtualCardApplication: () => {
          return isVirtualCard;
        },
        isErrorResult: (_, e) => {
          const error = e.data.applicationRes.error;
          return !!(error?.reason || error?.message);
        },
        isDownPaymentAuthDeclinedError: (_, e) => {
          const app = e.data.applicationRes.result;
          if (!app) {
            return false;
          }

          return !!app.statusCodes?.some(
            (code) =>
              code ===
              APPLICATION_STATUS_CODES.DownPaymentAuthDeclinedDeclinedRetryPaymentMethod
          );
        },
        isIneligibleAddress: (_, e) => {
          const app = e.data.applicationRes.result;
          if (!app) {
            return false;
          }
          return isIneligibleAddress(app);
        },
        isValidApplicationAndMatchesPreviouslyApprovedOrder: (_, e) => {
          const app = e.data.applicationRes.result;
          if (!app) {
            return false;
          }
          return (
            matchesPreviouslyApproved(app, order) &&
            isValidApplication(app, merchantPaymentProducts)
          );
        },
        needsFullIIN: (_, e) => {
          const app = e.data.applicationRes.result;
          if (!app) {
            return false;
          }
          return needsFullIIN(app);
        },
        needsIDVerification: (_, e) => {
          const app = e.data.applicationRes.result;
          if (!app) {
            return false;
          }
          return (
            !enableAlloyJourney &&
            enableIDVerification &&
            needsIDVerification(app)
          );
        },
        needsIDVerificationAlloy: (_, e) => {
          const app = e.data.applicationRes.result;
          if (!app) {
            return false;
          }
          return (
            enableIDVerification &&
            enableAlloyJourney &&
            needsIDVerification(app)
          );
        },
        isPoBoxAddressIneligible: (_, e) => {
          const statusCode = e.data.statusCode;
          return (
            statusCode ===
            APPLICATION_STATUS_CODES.EligibilityIneligibleInvalidAddressPOBox
          );
        },
        isBuyerHashFailed: (_, e) => {
          const statusCode = e.data.statusCode;
          return (
            statusCode ===
            APPLICATION_STATUS_CODES.ApplicationCancelledBuyerHashCheckFailed
          );
        },
        isCreditFreeze: (_, e) => {
          const statusCode = e.data.statusCode;
          return [
            APPLICATION_STATUS_CODES.DecisionIncompleteIncompleteCreditFreeze,
            APPLICATION_STATUS_CODES.KYCIncompleteCreditFreeze,
          ].includes(statusCode);
        },
        isKycDenial: (_, e) => {
          const statusCode = e.data.statusCode;
          return [
            APPLICATION_STATUS_CODES.DecisionDeniedInsufficientData,
            APPLICATION_STATUS_CODES.KYCFailed,
          ].includes(statusCode);
        },
        isPreviousDenialIneligible: (_, e) => {
          const statusCode = e.data.statusCode;
          return (
            statusCode ===
            APPLICATION_STATUS_CODES.DecisionIneligiblePreviousDenial
          );
        },
        isMaxCardIneligible: (_, e) => {
          const statusCode = e.data.statusCode;
          return (
            statusCode ===
            APPLICATION_STATUS_CODES.DecisionIneligibleMaxCardAttempt
          );
        },
        isOutstandingLoansIneligible: (_, e) => {
          const statusCode = e.data.statusCode;
          return (
            statusCode ===
            APPLICATION_STATUS_CODES.DecisionIneligibleOutstandingLoans
          );
        },
        isAgeIneligible: (_, e) => {
          const statusCode = e.data.statusCode;
          return (
            statusCode ===
            APPLICATION_STATUS_CODES.EligibilityIneligibleBuyerAge
          );
        },
        isAgeAlabamaMilitary: (_, e) => {
          const statusCode = e.data.statusCode;
          return (
            statusCode ===
            APPLICATION_STATUS_CODES.EligibilityIneligibleBuyerAgeAL
          );
        },
        isBuyerStatusIneligible: (_, e) => {
          const statusCode = e.data.statusCode;
          return (
            statusCode ===
            APPLICATION_STATUS_CODES.EligibilityIneligibleBuyerStatus
          );
        },
        isBuyerSkippedInstallments: (_, e) => {
          const statusCode = e.data.statusCode;
          return (
            statusCode ===
            APPLICATION_STATUS_CODES.DecisionIneligibleIneligibleSkippedInstallments
          );
        },
        isLocationIneligible: (_, e) => {
          const statusCode = e.data.statusCode;
          return [
            APPLICATION_STATUS_CODES.EligibilityIneligibleBuyerRegion,
            APPLICATION_STATUS_CODES.EligibilityIneligibleBuyerCountry,
          ].includes(statusCode);
        },
        isNeedsActionWarning: (_, e) => {
          const statusCode = e.data.statusCode;
          return [
            APPLICATION_STATUS_CODES.FraudIncompleteExtendedFraudAlert,
            APPLICATION_STATUS_CODES.FraudIncompleteHighRisk,
            APPLICATION_STATUS_CODES.FraudIncompleteLowRisk,
            APPLICATION_STATUS_CODES.KYCIncompleteFailed,
          ].includes(statusCode);
        },
        isFraudDenial: (_, e) => {
          const statusCode = e.data.statusCode;
          return statusCode === APPLICATION_STATUS_CODES.FraudFailed;
        },
        isCreditDenial: (_, e) => {
          const statusCode = e.data.statusCode;
          return [
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedCredit,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedNoCreditFile,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedNoCreditScore,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedThinFile,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedRepossessions,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedForeclosure,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedDerogatoryRecord,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedTradePastDue,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedCollections,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedRevolvingCreditUtilization,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedInsufficientCapacity,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedAuthorizedTradelines,
            APPLICATION_STATUS_CODES.DecisionDeniedLowCreditLimit,
            APPLICATION_STATUS_CODES.DecisionDeniedLowFicoLowCreditLimit,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedLowFICO,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedCrust,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedBreadChargeOff,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedPastLoanOverdue,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedLoanCurrentlyOverdue,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedChargeOff,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedAccountPastDue,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedInquiries,
            APPLICATION_STATUS_CODES.DownPaymentAuthDeclined,
            APPLICATION_STATUS_CODES.DecisionDeniedDenied,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedCreditAbuse,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedCreditopticsDeceased,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedCreditopticsDecline,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedBureauDeceased,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedPreciseidDeceased,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedPreciseidDecline,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedEquifaxDeceased,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedBureauData,
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedLowBureau,
            APPLICATION_STATUS_CODES.FraudDeniedPreciseIDDenied,
          ].includes(statusCode);
        },
        isSanctionsOFACDenial: (_, e) => {
          const statusCode = e.data.statusCode;
          return (
            statusCode === APPLICATION_STATUS_CODES.SanctionsIncompleteFailed
          );
        },
        isSanctionsDenial: (_, e) => {
          const statusCode = e.data.statusCode;
          return statusCode === APPLICATION_STATUS_CODES.SanctionsFailed;
        },
        isFraudAlertDenial: (_, e) => {
          const statusCode = e.data.statusCode;
          return (
            statusCode ===
            APPLICATION_STATUS_CODES.DecisionDeniedDeniedFraudAlert
          );
        },
        isCapacityRecheck: (_, e) => {
          const statusCode = e.data.statusCode;
          return (
            statusCode ===
              APPLICATION_STATUS_CODES.CapacityCancelledContingent ||
            statusCode === APPLICATION_STATUS_CODES.CapacityCancelledPartial
          );
        },
        isIDVerificationSuccess: (_, e) => {
          const resultResponse = e.data.evaluateIDResponse.result;
          if (!resultResponse) {
            return false;
          }
          const { statusCode } = resultResponse;

          return (
            statusCode === APPLICATION_STATUS_CODES.KYCPassed ||
            statusCode === APPLICATION_STATUS_CODES.FraudIncompleteLowRisk
          );
        },
        isIDVerificationFormError: (_, e) => {
          return !!e.data.evaluateIDResponse.error;
        },
        isIDVerificationRetryError: (_, e) => {
          const resultResponse = e.data.evaluateIDResponse.result;
          if (!resultResponse) {
            return false;
          }
          const {
            metadata: { attempts, maxAttempts },
          } = resultResponse;

          return !!(attempts && maxAttempts && attempts < maxAttempts);
        },
        isIDVerificationErrorMaxAttemptsExceeded: (_, e) => {
          const resultResponse = e.data.evaluateIDResponse.result;
          if (!resultResponse) {
            return false;
          }
          const {
            metadata: { attempts, maxAttempts },
          } = resultResponse;

          return !!(attempts && maxAttempts && attempts >= maxAttempts);
        },
        isIDVerificationNeedsActionWarning: (_, e) => {
          const resultResponse = e.data.evaluateIDResponse.result;
          if (!resultResponse) {
            return false;
          }
          const { statusCode } = resultResponse;
          if (needsManualReview(statusCode) || hasFailed(statusCode)) {
            return (
              statusCode ===
                ID_VERIFICATION_STATUS_CODES.DocumentEvaluationExtendedFraudAlert ||
              statusCode ===
                ID_VERIFICATION_STATUS_CODES.DocumentEvaluationHighRisk
            );
          }
          return false;
        },
        isIDVerificationFailure: (_, e) => {
          const resultResponse = e.data.evaluateIDResponse.result;
          if (!resultResponse) {
            return false;
          }
          const { statusCode } = resultResponse;
          if (needsManualReview(statusCode) || hasFailed(statusCode)) {
            return statusCode === ID_VERIFICATION_STATUS_CODES.KYCFailIDDenial;
          }
          return false;
        },
      },
    });

    // Ensure we re-create application if order changes
    useEffect(() => {
      if (
        lastOrderRef.current &&
        order &&
        !isEqual(order, lastOrderRef.current)
      ) {
        applicationService.send('SEND_CREATE_APPLICATION');
      }
      lastOrderRef.current = order;
    }, [order]);

    // Auth service subscriber
    useEffect(() => {
      if (authState.matches('authenticated.complete.initial')) {
        applicationService.send('SEND_GET_LATEST_APPLICATION');
        return;
      } else if (authState.matches('authenticated')) {
        applicationService.send('SEND_APPLICATION_READY');
      }
    }, [authState]);

    useEffect(() => {
      const state = applicationService.getSnapshot();
      // Do not fetch or create a new application if we already fetched or created one
      if (
        buyerState.matches('ready.complete.initial') &&
        !state.matches('ready.approved')
      ) {
        // Complete buyer - try to fetch and then create if no approved application
        applicationService.send('SEND_GET_LATEST_APPLICATION');
      } else if (
        // Complete Buyer - updated contact or iin dob - Create a new application
        buyerState.matches('ready.complete.contactUpdated') ||
        buyerState.matches('ready.complete.dobUpdated')
      ) {
        applicationService.send('SEND_CREATE_APPLICATION');
        // Complete Buyer - updated but iin dob or contact was not changed:
        // try to fetch and then create if no approved application
      } else if (
        buyerState.matches('ready.complete.contactUpdatedWithoutChange') ||
        buyerState.matches('ready.complete.dobUpdatedWithoutChange')
      ) {
        applicationService.send('SEND_GET_LATEST_APPLICATION');
        // RBC buyer with identity  - try to fetch and then create if no approved application
      } else if (buyerState.matches('ready.completeIdentity')) {
        applicationService.send('SEND_GET_LATEST_APPLICATION');
      }
    }, [buyerState]);

    return (
      <ApplicationContext.Provider value={applicationService}>
        {children}
      </ApplicationContext.Provider>
    );
  };

export interface UseApplicationMachineValues extends ApplicationMachineContext {
  state: ApplicationService['state'];
  fetchApplication: FetchHandlerWrapper<Application, string>;
  applicationCheckout: HandlerWrapper<ApplicationCheckoutOptions, Application>;
  applicationPrepareCheckout: HandlerWrapper<
    ApplicationPrepareCheckoutOptions,
    Application
  >;
  reCreateApplication: HandlerWrapper<ReCreateApplicationOptions, Application>;
  evaluateIDVerificationImages: HandlerWrapper<
    SendEvaluateIDVerificationImagesOptions,
    IDVerificationEvaluationResponse
  >;
  resetApplicationState: () => void;
  assignSelectedOffer: (selectedOffer: Offer) => void;
  assignPaymentAgreementDocument: (
    paymentAgreementDocument: PaymentAgreementDocument
  ) => void;
  assignIDVerificationFrontImageData: (frontImageData: Blob | null) => void;
  assignIDVerificationBackImageData: (backImageData: Blob | null) => void;
  alloyIDVerificationSuccess: () => void;
  alloyIDVerificationError: () => void;
}

/**
 * A hook to allow interaction with the application machine and its state
 * @returns UseApplicationValues
 */
export const useApplicationMachine = (): UseApplicationMachineValues => {
  const applicationService = useContext(ApplicationContext);

  if (!applicationService) {
    throw new Error('Application machine service is null!');
  }

  const [state, send] = useActor(applicationService);
  const {
    context: {
      application,
      selectedOffer,
      paymentAgreementDocument,
      idVerification,
    },
  } = applicationService.getSnapshot();

  const fetchApplication: FetchHandlerWrapper<Application, string> = async (
    applicationID
  ) => {
    return new Promise((resolve, reject) => {
      send({
        type: 'SEND_GET_APPLICATION',
        applicationID,
        resolve,
        reject,
      });
    });
  };

  const applicationCheckoutWrapper: HandlerWrapper<
    ApplicationCheckoutOptions,
    Application
  > = async (options) => {
    return new Promise((resolve, reject) => {
      send({
        type: 'SEND_APPLICATION_CHECKOUT',
        options,
        resolve,
        reject,
      });
    });
  };

  const applicationPrepareCheckoutWrapper: HandlerWrapper<
    ApplicationPrepareCheckoutOptions,
    Application
  > = async (options) => {
    return new Promise((resolve, reject) => {
      send({
        type: 'SEND_APPLICATION_PREPARE_CHECKOUT',
        options,
        resolve,
        reject,
      });
    });
  };

  const reCreateApplication: HandlerWrapper<
    ReCreateApplicationOptions,
    Application
  > = async (options) => {
    return new Promise((resolve, reject) => {
      send({
        type: 'SEND_RECREATE_APPLICATION',
        options,
        resolve,
        reject,
      });
    });
  };

  const evaluateIDVerificationImagesWrapper: HandlerWrapper<
    SendEvaluateIDVerificationImagesOptions,
    IDVerificationEvaluationResponse
  > = async (options) => {
    return new Promise((resolve, reject) => {
      send({
        type: 'SEND_EVALUATE_ID_VERIFICATION_IMAGES',
        options,
        resolve,
        reject,
      });
    });
  };

  const resetApplicationState = (): void => {
    send('SEND_RESET_APPLICATION_STATE');
  };

  const assignSelectedOffer = (offer: Offer): void => {
    send({
      type: 'SEND_ASSIGN_SELECTED_OFFER',
      selectedOffer: offer,
    });
  };

  const assignPaymentAgreementDocument = (
    document: PaymentAgreementDocument
  ): void => {
    send({
      type: 'SEND_ASSIGN_PAYMENT_AGREEMENT_DOCUMENT',
      paymentAgreementDocument: document,
    });
  };

  const assignIDVerificationFrontImageData = (
    frontImageData: Blob | null
  ): void => {
    send({
      type: 'SEND_ASSIGN_ID_VERIFICATION_FRONT_IMAGE_DATA',
      frontImageData,
    });
  };

  const assignIDVerificationBackImageData = (
    backImageData: Blob | null
  ): void => {
    send({
      type: 'SEND_ASSIGN_ID_VERIFICATION_BACK_IMAGE_DATA',
      backImageData,
    });
  };

  const alloyIDVerificationSuccess = (): void => {
    send({
      type: 'SEND_ID_VERIFICATION_ALLOY_SUCCESS',
    });
  };

  const alloyIDVerificationError = (): void => {
    send({
      type: 'SEND_ID_VERIFICATION_ALLOY_ERROR',
    });
  };

  return {
    state,
    application,
    selectedOffer,
    paymentAgreementDocument,
    idVerification,
    resetApplicationState,
    fetchApplication,
    applicationCheckout: applicationCheckoutWrapper,
    applicationPrepareCheckout: applicationPrepareCheckoutWrapper,
    evaluateIDVerificationImages: evaluateIDVerificationImagesWrapper,
    reCreateApplication,
    assignSelectedOffer,
    assignPaymentAgreementDocument,
    assignIDVerificationFrontImageData,
    assignIDVerificationBackImageData,
    alloyIDVerificationSuccess,
    alloyIDVerificationError,
  };
};

export const ApplicationSubscriber: FunctionComponent = () => {
  const { state } = useApplicationMachine();
  return (
    <pre className="text-sm">
      {JSON.stringify({ value: state.value }, null, 2)}
      {JSON.stringify({ value: state?.context }, null, 2)}
    </pre>
  );
};

/**
 * Returns shipping descriptions as localized string
 * @param orderItems
 * @param locale
 * @returns OrderItem[]
 */
export function stringifyShippingDescription(
  orderItems: OrderItem[],
  locale: Locale
): OrderItem[] {
  return orderItems.map((item) => ({
    ...item,
    shippingDescription: isLocalizedString(item.shippingDescription)
      ? item.shippingDescription[locale]
      : item.shippingDescription,
  }));
}

/**
 * Ensures an application is approved with valid offers
 * @param application Application
 * @param merchantPaymentProducts
 * @returns boolean
 */
export function isValidApplication(
  application: Application,
  merchantPaymentProducts: MerchantPaymentProduct[]
): boolean {
  const isValidApp = !!(
    application &&
    application.status === APPLICATION_STATUS.APPROVED &&
    application.offers?.some(
      (offer) =>
        offer.paymentAgreement.status === 'OFFERED' &&
        isBefore(new Date(), parseISO(offer.paymentAgreement.expiresAt))
    )
  );
  const isValidPaymentProducts =
    !!application.offers &&
    hasValidOffers(application.offers, merchantPaymentProducts);
  return isValidApp && isValidPaymentProducts;
}

/**
 * Determine if an order on an application matches the current order
 * @param validApplication Application
 * @param order Order
 * @returns boolean
 */
function matchesPreviouslyApproved(
  validApplication: Application,
  order: Order
) {
  return (
    order &&
    totalPriceIsCovered(validApplication, order) &&
    orderEqual(validApplication.order, order)
  );
}

/**
 * Gets origin code from an application status code for ID Verification
 * @param statusCodes
 * @returns '04' | '05'
 */
const getOriginFromStatus = (statusCodes: string[]): '04' | '05' => {
  const idVerificationStatusCode = statusCodes.find((statusCode) => {
    return APPLICATION_ID_VERIFICATION_STATUS_CODES.includes(statusCode);
  });

  if (!idVerificationStatusCode) {
    throw new Error('Unable to get origin from application status code!');
  }

  return idVerificationStatusCode.toString().slice(0, 2) as '04' | '05';
};

/**
 * Determine if an application is an in store application
 * @param application
 * @returns boolean
 */
function isUnexpiredInStoreApplication(
  namespace: NamespacePath,
  application: Application
): boolean {
  return !!(
    namespace === NAMESPACE_MAP.InStore &&
    application.offers?.some(
      (offer) =>
        offer.shortCode?.value &&
        isBefore(new Date(), parseISO(offer.shortCode.expiresAt))
    )
  );
}

/**
 * Checks if the given application is opted out.
 * @param application
 * @returns boolean
 */
function isOptedOut(
  application: FilteredApplication
): application is OptedOut &
  Partial<Pick<Application, 'id' | 'buyerID' | 'status'>> {
  return 'optedOut' in application && application.optedOut === true;
}
