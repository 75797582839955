import { FunctionComponent, createContext, useContext, useEffect } from 'react';
import { useActor, useInterpret } from '@xstate/react';
import { assign, InterpreterFrom } from 'xstate';
import * as yup from 'yup';
import { isRight } from 'fp-ts/lib/Either';
import { useRouter } from 'next/router';
import { isEqual } from 'lodash';
import {
  Buyer,
  Address,
  Name,
  ClientAppConfig,
  ContactOutgoing,
  OutgoingBuyer,
  Contact,
} from '@ads-bread/shared/bread/codecs';
import {
  buyerMachine,
  BuyerMachineContext,
  BuyerServiceErrorData,
} from '../lib/machines/buyerMachine';
import { FCWithChildren } from '../lib/types';
import { addCountryCodeToPhoneNumber } from '../lib/format-number';
import {
  identify,
  setAdditionalContext as setAdditionalAnalyticsContext,
} from '../lib/analytics';
import { HandlerWrapper, toResultResponse } from '../lib/handlers/base';
import { getBuyer, getBuyerPhoneAndEmail } from '../lib/handlers/get-buyer';
import { getCart } from '../lib/handlers/get-cart';
import { useNamespace } from '../lib/hooks/useNamespace';
import { updateBuyer } from '../lib/handlers/update-buyer';
import { createBuyer } from '../lib/handlers/create-buyer';
import { updateContact } from '../lib/handlers/update-contact';
import { useFetch } from '../lib/hooks/apiFetch';
import { useAddressSchema } from '../lib/hooks/useAddressSchema';
import { logger } from '../lib/logger';
import { hasAddressChanged } from '../lib/address';
import { useAuthentication } from './AuthenticationContext';
import { useAppConfig } from './AppConfigContext';
import { useBuyer, useMerchantID } from './XPropsContext';

export const BUYER_ERROR_REASONS = {
  IDENTITY_MISMATCH: 'Identity_Mismatch',
  BUYER_VALIDATION: 'Buyer_Validation',
  EMAIL_ALREADY_EXISTS: 'Email_Already_Exists',
  PHONE_ALREADY_EXISTS: 'Phone_Already_Exists',
  BUYER_CONFLICT: 'Buyer_Conflict',
};

const BUYER_ERROR_META_DATA_KEYS = {
  IIN: 'identity.iin',
};

export type BuyerService = InterpreterFrom<typeof buyerMachine>;

export const BuyerContext = createContext<BuyerService | null>(null);

export type BuyerProviderProps = {
  context?: BuyerMachineContext;
};

export const BuyerMachineProvider: FCWithChildren<BuyerProviderProps> = ({
  children,
  context = {},
}) => {
  const namespace = useNamespace();
  const { query } = useRouter();
  const xPropsBuyer = useBuyer();
  const { merchantID } = useMerchantID();
  const { buyerIdentityAttributes } = useAppConfig();
  const addressSchema = useAddressSchema();
  const { state: authState, logout } = useAuthentication();
  const apiFetch = useFetch();

  const initialContext: BuyerMachineContext = {
    buyerName: context.buyerName || xPropsBuyer.name || null,
    buyerID: context.buyerID || xPropsBuyer.buyerID || null,
    email: context.email || xPropsBuyer.email || null,
    phone: context.phone || xPropsBuyer.phone || null,
    iin: context.iin || null,
    dob: context.dob || null,
    shippingAddress:
      context.shippingAddress || xPropsBuyer.shippingAddress || null,
    billingAddress:
      context.billingAddress || xPropsBuyer.billingAddress || null,
    buyer: context.buyer || null,
  };

  const buyerService = useInterpret(buyerMachine, {
    context: initialContext,
    services: {
      createBuyer: async (_, event) => {
        const resolve = event.resolve;
        const reject = event.reject;

        try {
          const buyer = event.buyer;
          const buyerRes = await createBuyer(buyer, apiFetch);

          if (!event.contact) {
            return { buyerRes: buyerRes, resolve };
          }
          // Update the contact if passed
          if (buyerRes.result) {
            const contactRes = await updateContact(
              buyerRes.result,
              event.contact,
              apiFetch
            );

            if (contactRes.result) {
              const updatedBuyer = {
                ...buyerRes.result,
                contacts: {
                  ...buyerRes.result.contacts,
                  [buyerRes.result.primaryContactID]: contactRes.result,
                },
              };
              // Just return the concatenated result response if successful
              return {
                buyerRes: toResultResponse(updatedBuyer),
                resolve,
              };
            }
          }

          return { buyerRes, resolve };
        } catch (error) {
          throw { error, reject };
        }
      },
      updateBuyer: async (ctx, event) => {
        const resolve = event.resolve;
        const reject = event.reject;
        const contextBuyer = ctx.buyer;
        try {
          if (!contextBuyer) {
            throw new Error('Error updating buyer. No buyer in context.');
          }

          const updatedBuyer: Buyer = {
            ...contextBuyer,
            ...event.buyer,
            identity: {
              ...contextBuyer.identity,
              ...event.buyer.identity,
            },
            employment: {
              ...contextBuyer.employment,
              ...event.buyer?.employment,
            },
          };

          if (event.contact) {
            const buyerRes = await updateBuyer(updatedBuyer, apiFetch);

            // Don't bother updating contact if buyer update failed
            if (buyerRes.error) {
              return {
                buyerRes,
                identity: event.buyer.identity,
                resolve,
              };
            }

            const contactRes = await updateContact(
              updatedBuyer,
              event.contact,
              apiFetch
            );
            if (contactRes.error) {
              return {
                buyerRes: contactRes,
                identity: event.buyer.identity,
                resolve,
              };
            }

            // Append the updated contact
            const updatedBuyerWithContact = {
              ...buyerRes.result,
              contacts: {
                ...buyerRes.result.contacts,
                [buyerRes.result.primaryContactID]: contactRes.result,
              },
            };

            return {
              buyerRes: toResultResponse(updatedBuyerWithContact),
              contactRes,
              identity: event.buyer.identity,
              resolve,
            };
          } else {
            const otherBuyerRes = await updateBuyer(updatedBuyer, apiFetch);

            return {
              buyerRes: otherBuyerRes,
              identity: event.buyer.identity,
              resolve,
            };
          }
        } catch (error) {
          throw { error, reject };
        }
      },
      fetchBuyer: async (_, event) => {
        const resolve = event.resolve;
        const reject = event.reject;
        try {
          const buyerRes = await getBuyer(apiFetch);
          return { resolve, buyerRes };
        } catch (error) {
          logout();
          throw { error, reject };
        }
      },
      fetchPartialBuyer: async (_, event) => {
        const resolve = event.resolve;
        const reject = event.reject;
        try {
          if (!xPropsBuyer.buyerID) {
            throw new Error('Unable to fetch partial buyer without buyerID!');
          }

          const buyerRes = await getBuyerPhoneAndEmail(
            apiFetch,
            xPropsBuyer.buyerID
          );
          return { buyerRes, resolve };
        } catch (error) {
          logout();
          throw { error, reject };
        }
      },
      prepareCartBuyer: async () => {
        const { cartID } = query;
        if (!cartID || typeof cartID !== 'string') {
          throw new Error('Cannot prepare a cart without cartID param!');
        }
        const cartRes = await getCart(apiFetch, cartID);

        if (cartRes.error) {
          throw cartRes.error;
        }

        return { cartRes };
      },
      getErrorReasonFromResponse: (_, event) => {
        return new Promise((resolve) => {
          const reason = event.data.buyerRes.error?.reason || 'unknown';
          resolve({ reason });
        });
      },
    },
    actions: {
      assignBuyerName: assign({
        buyerName: (_, event) => {
          return event.buyerName;
        },
      }),
      assignBuyerEmail: assign({
        email: (_, event) => {
          return event.email;
        },
      }),
      assignBuyerPhone: assign({
        phone: (_, event) => {
          return event.phone;
        },
      }),
      assignBuyerIIN: assign({
        iin: (_, event) => {
          return event.iin;
        },
      }),
      assignBuyerDOB: assign({
        dob: (_, event) => {
          return event.dob;
        },
      }),
      assignBuyerShippingAddress: assign({
        shippingAddress: (_, event) => {
          return event.shippingAddress;
        },
      }),
      assignBuyerBillingAddress: assign({
        billingAddress: (_, event) => {
          return event.billingAddress;
        },
      }),
      assignBuyer: assign({
        buyer: (_, event) => {
          return event.data.buyerRes.result;
        },
      }),
      assignPartialBuyer: assign({
        phone: (_, event) => {
          return event.data.buyerRes.result?.phone || '';
        },
        email: (_, event) => {
          return event.data.buyerRes.result?.email || '';
        },
      }),
      assignCartBuyer: assign({
        buyerName: (ctx, event) => {
          return event.data.cartRes.result?.contact.name || ctx.buyerName;
        },
        phone: (_, event) => {
          return addCountryCodeToPhoneNumber(
            event.data.cartRes.result?.contact.phone || ''
          );
        },
        email: (_, event) => {
          return event.data.cartRes.result?.contact.email || null;
        },
        shippingAddress: (_, event) => {
          return event.data.cartRes.result?.contact.shippingAddress || null;
        },
        billingAddress: (_, event) => {
          return event.data.cartRes.result?.contact.billingAddress || null;
        },
      }),
      identifyBuyer: (_, event) => {
        const buyerId = event.data.buyerRes.result?.id;
        if (buyerId) {
          identify(buyerId);
        }
      },
      handleBuyerDone: (_, event) => {
        event.data.resolve(event.data.buyerRes);
      },
      handleBuyerServiceError: (_, event) => {
        const errorData = event.data as BuyerServiceErrorData;
        errorData.reject(errorData.error);
      },
      handleBuyerEmailAnalytics: (_, event) => {
        const email = event.email;
        if (email) {
          setAdditionalAnalyticsContext({ email });
        }
      },
      handleBuyerPhoneAnalytics: (_, event) => {
        const phone = event.phone;
        if (phone) {
          setAdditionalAnalyticsContext({ phone });
        }
      },
      resetContext: assign({
        ...initialContext,
      }),
      logout: () => {
        logout();
      },
      logError: (ctx, event) => {
        const err =
          event.data instanceof Error ? event.data : { message: event.data };
        logger.error(
          { err, buyerID: ctx.buyerID, merchantID },
          `Error in BuyerMachineContext: ${event.type}`
        );
      },
    },
    guards: {
      isComplete: (_, event) => {
        const buyer = event.data.buyerRes.result;

        const isComplete = !!(
          buyer &&
          isCompleteBuyer(buyer, buyerIdentityAttributes, addressSchema)
        );

        return isComplete;
      },
      hasCompleteIdentity: (_, event) => {
        const buyer = event.data.buyerRes.result;
        const isComplete = !!(
          buyer &&
          buyerIdentityAttributes.every((attr) => !!buyer.identity[attr])
        );

        return isComplete;
      },
      isValidDecodedBuyer: (_, event) => {
        const decoded = Buyer.decode(event.data.buyerRes.result);
        return isRight(decoded);
      },
      hasBuyerResult: (_, event) => {
        return !!event.data.buyerRes.result;
      },
      isCompleteAndDobUpdatedWithoutChange: (ctx, event) => {
        const buyer = event.data.buyerRes.result;
        const eventBuyerIdentity = event.data.identity;

        // There was no attempt to update dob in event
        if (!eventBuyerIdentity.birthDate) {
          return false;
        }

        const currentBirthDate = ctx.buyer?.identity.birthDate;
        const resultBirthDate = buyer?.identity.birthDate;

        const isIinDobUpdatedWithoutChange =
          currentBirthDate === resultBirthDate;

        const isComplete =
          buyer &&
          isCompleteBuyer(buyer, buyerIdentityAttributes, addressSchema);

        return !!(isIinDobUpdatedWithoutChange && isComplete);
      },
      isCompleteAndDobUpdated: (ctx, event) => {
        const buyer = event.data.buyerRes.result;
        const eventBuyerIdentity = event.data.identity;

        // There was no attempt to update dob in event
        if (!eventBuyerIdentity.birthDate) {
          return false;
        }

        const currentBirthDate = ctx.buyer?.identity.birthDate;
        const resultBirthDate = buyer?.identity.birthDate;

        const isIinDobUpdated = currentBirthDate !== resultBirthDate;

        const isComplete =
          buyer &&
          isCompleteBuyer(buyer, buyerIdentityAttributes, addressSchema);

        return !!(isIinDobUpdated && isComplete);
      },
      isCompleteAndContactUpdatedWithoutChange: (ctx, event) => {
        const buyer = event.data.buyerRes.result;
        const contact = event.data.contactRes?.result;
        const currentBuyer = ctx.buyer;

        // There was no attempt to update contact
        if (!contact) {
          return false;
        }

        const isContactUpdated = isPrimaryContactUpdated(currentBuyer, contact);
        const isComplete = !!(
          buyer &&
          isCompleteBuyer(buyer, buyerIdentityAttributes, addressSchema)
        );

        return !isContactUpdated && isComplete;
      },
      isCompleteAndContactUpdated: (ctx, event) => {
        const buyer = event.data.buyerRes.result;
        const contact = event.data.contactRes?.result;
        const currentBuyer = ctx.buyer;

        // There was no attempt to update contact
        if (!contact) {
          return false;
        }

        const isContactUpdated = isPrimaryContactUpdated(currentBuyer, contact);
        const isComplete = !!(
          buyer &&
          isCompleteBuyer(buyer, buyerIdentityAttributes, addressSchema)
        );

        return isContactUpdated && isComplete;
      },
      isCartComplete: (_, event) => {
        const cartDataContact = event.data.cartRes.result?.contact;
        if (!cartDataContact) {
          return false;
        }
        return !!(
          cartDataContact.name &&
          cartDataContact.billingAddress &&
          addressSchema.isValidSync(cartDataContact.billingAddress)
        );
      },
      isBuyerConflictErrorResponse: (_, event) => {
        return (
          BUYER_ERROR_REASONS.BUYER_CONFLICT ===
          event.data.buyerRes.error?.reason
        );
      },
      isBuyerValidationError: (_, event) => {
        return (
          BUYER_ERROR_REASONS.BUYER_VALIDATION ===
            event.data.buyerRes.error?.reason &&
          !!event.data.buyerRes.error.metadata[BUYER_ERROR_META_DATA_KEYS.IIN]
        );
      },
      isErrorResponse: (_, event) => {
        return !!event.data.buyerRes.error;
      },
      isPartialBuyerSuccessResponse: (_, event) => {
        return !!event.data.buyerRes.result;
      },
      isVerifiedEmailError: (_, event) => {
        const { reason } = event.data;
        return [
          BUYER_ERROR_REASONS.IDENTITY_MISMATCH,
          BUYER_ERROR_REASONS.BUYER_VALIDATION,
          BUYER_ERROR_REASONS.EMAIL_ALREADY_EXISTS,
          BUYER_ERROR_REASONS.PHONE_ALREADY_EXISTS,
        ].includes(reason);
      },
      isCartBuyer: () => {
        return namespace === 'cart/[cartID]';
      },
    },
  });

  useEffect(() => {
    if (authState.matches('authenticated.complete')) {
      // Returning buyer
      buyerService.send('SEND_FETCH_BUYER');
      return;
    } else if (authState.matches('authenticated')) {
      buyerService.send('SEND_BUYER_READY');
    } else if (authState.matches('unAuthenticated.exchanging')) {
      // Exchanging buyer
      buyerService.send('SEND_FETCHING_PARTIAL_BUYER');
    }
  }, [authState, buyerService]);

  /**
   * Updates shipping address if xProps are updated via BreadSDK setShippingAddress
   */
  useEffect(() => {
    const { context: buyerServiceContext } = buyerService.getSnapshot();
    if (
      xPropsBuyer.shippingAddress &&
      !isEqual(buyerServiceContext.shippingAddress, xPropsBuyer.shippingAddress)
    ) {
      buyerService.send({
        type: 'SEND_UPDATE_BUYER_SHIPPING_ADDRESS',
        shippingAddress: xPropsBuyer.shippingAddress,
      });
    }
  }, [buyerService, xPropsBuyer.shippingAddress]);

  return (
    <BuyerContext.Provider value={buyerService}>
      {children}
    </BuyerContext.Provider>
  );
};

export interface UseBuyerMachineValues extends BuyerMachineContext {
  state: BuyerService['state'];
  createBuyer: HandlerWrapper<OutgoingBuyer, Buyer, ContactOutgoing>;
  updateBuyer: HandlerWrapper<
    OutgoingBuyer,
    Buyer,
    ContactOutgoing | undefined
  >;
  resetBuyerState: () => void;
  assignBuyerName: (buyerName: Name) => void;
  assignBuyerEmail: (email: string) => void;
  assignBuyerPhone: (phone: string) => void;
  assignBuyerIIN: (iin: string) => void;
  assignBuyerDOB: (dob: string) => void;
  assignBuyerShippingAddress: (shippingAddress: Address) => void;
  assignBuyerBillingAddress: (billingAddress: Address) => void;
}

export const useBuyerMachine = (): UseBuyerMachineValues => {
  const buyerService = useContext(BuyerContext);

  if (!buyerService) {
    throw new Error('Buyer machine service is null!');
  }
  const [state, send] = useActor(buyerService);

  const {
    context: {
      buyer,
      buyerName,
      buyerID,
      email,
      phone,
      iin,
      dob,
      shippingAddress,
      billingAddress,
    },
  } = buyerService.getSnapshot();

  const updateBuyerWrapper: HandlerWrapper<
    OutgoingBuyer,
    Buyer,
    ContactOutgoing | undefined
  > = async (updatedBuyer, contact) => {
    return new Promise((resolve, reject) => {
      send({
        type: 'SEND_UPDATE_BUYER',
        buyer: updatedBuyer,
        contact,
        resolve,
        reject,
      });
    });
  };

  const createBuyerWrapper: HandlerWrapper<
    OutgoingBuyer,
    Buyer,
    ContactOutgoing
  > = async (newBuyer, contact) => {
    return new Promise((resolve, reject) => {
      send({
        type: 'SEND_CREATE_BUYER',
        buyer: newBuyer,
        contact,
        resolve,
        reject,
      });
    });
  };

  const resetBuyerState = (): void => {
    send('SEND_RESET_BUYER_STATE');
  };

  const assignBuyerName = (name: Name): void => {
    send({
      type: 'SEND_UPDATE_BUYER_NAME',
      buyerName: name,
    });
  };

  const assignBuyerEmail = (buyerEmail: string): void => {
    send({
      type: 'SEND_UPDATE_BUYER_EMAIL',
      email: buyerEmail,
    });
  };

  const assignBuyerPhone = (buyerPhone: string): void => {
    send({
      type: 'SEND_UPDATE_BUYER_PHONE',
      phone: buyerPhone,
    });
  };

  const assignBuyerIIN = (buyerIIN: string): void => {
    send({
      type: 'SEND_UPDATE_BUYER_IIN',
      iin: buyerIIN,
    });
  };

  const assignBuyerDOB = (buyerDOB: string): void => {
    send({
      type: 'SEND_UPDATE_BUYER_DOB',
      dob: buyerDOB,
    });
  };

  const assignBuyerShippingAddress = (buyerShippingAddress: Address): void => {
    send({
      type: 'SEND_UPDATE_BUYER_SHIPPING_ADDRESS',
      shippingAddress: buyerShippingAddress,
    });
  };

  const assignBuyerBillingAddress = (buyerBillingAddress: Address): void => {
    send({
      type: 'SEND_UPDATE_BUYER_BILLING_ADDRESS',
      billingAddress: buyerBillingAddress,
    });
  };

  return {
    state,
    buyer,
    buyerName,
    buyerID,
    email,
    phone,
    iin,
    dob,
    shippingAddress,
    billingAddress,
    assignBuyerName,
    assignBuyerEmail,
    assignBuyerPhone,
    assignBuyerIIN,
    assignBuyerDOB,
    assignBuyerShippingAddress,
    assignBuyerBillingAddress,
    resetBuyerState,
    createBuyer: createBuyerWrapper,
    updateBuyer: updateBuyerWrapper,
  };
};

export const BuyerSubscriber: FunctionComponent = () => {
  const { state } = useBuyerMachine();
  return (
    <>
      <pre className="text-sm">
        {JSON.stringify({ value: state.value }, null, 2)}
      </pre>
      <pre className="text-sm">
        {JSON.stringify({ context: state.context }, null, 2)}
      </pre>
    </>
  );
};

export function isCompleteBuyer(
  buyer: Buyer,
  buyerIdentityAttributes: ClientAppConfig['buyerIdentityAttributes'],
  addressSchema: yup.ObjectSchema
): boolean {
  return (
    buyerIdentityAttributes.every((attr) => !!buyer.identity[attr]) &&
    hasCompleteContact(buyer, addressSchema)
  );
}

export function hasCompleteContact(
  buyer: Buyer,
  addressSchema: yup.ObjectSchema
): boolean {
  const primaryContact = buyer?.contacts?.[buyer?.primaryContactID];

  if (!primaryContact) {
    return false;
  }

  return addressSchema.isValidSync(primaryContact.address);
}

/**
 * Determine if buyer contact has been changed in comparison to contact supplied in params
 * @param buyer Buyer
 * @param contact Contact
 * @returns boolean
 */
export const isPrimaryContactUpdated = (
  buyer: Buyer | null,
  contact: Contact
): boolean => {
  const primaryContact = buyer?.contacts?.[buyer?.primaryContactID];
  if (!primaryContact) {
    return !!contact;
  }

  const isPrimaryAddressUpdated = hasAddressChanged(
    primaryContact.address,
    contact.address
  );
  const isPrimaryContactNameUpdated = !isEqual(
    primaryContact.name,
    contact.name
  );

  return isPrimaryAddressUpdated || isPrimaryContactNameUpdated;
};
