import { createMachine } from 'xstate';
import {
  RouteMachineServiceEvents,
  getRoutingMachineLevelEvents,
} from '../../components/RouteMachineService';
import { PageNames } from '../analytics';

export enum Routes {
  Intro = '/rbc',
  Email = '/rbc/email',
  Mobile = '/rbc/mobile',
  Verification = '/rbc/verification',
  BillingAddress = '/rbc/billing-address',
  Eligibility = '/rbc/eligibility',
  Terms = '/rbc/terms',
  Employment = '/rbc/employment',
  Review = '/rbc/review',
  LoanAgreement = '/rbc/loan-agreement',
  LoginEligibility = '/rbc/login-eligibility',
  ConfirmEmail = '/rbc/confirm-email',
  // Error Routes
  AgeAlabamaMilitary = '/rbc/errors/age-alabama-military',
  AgeIneligible = '/rbc/errors/age-ineligible',
  BuyerHashFailed = '/rbc/errors/buyer-hash-failed',
  BuyerSkippedInstallments = '/rbc/errors/buyer-skipped-installments',
  BuyerStatusIneligible = '/rbc/errors/buyer-status-ineligible',
  CreditDenial = '/rbc/errors/credit-denial',
  CreditFreeze = '/rbc/errors/credit-freeze',
  KycDenial = '/rbc/errors/kyc-denial',
  LoanStatusIneligible = '/rbc/errors/loan-status-ineligible',
  LocationIneligible = '/rbc/errors/location-ineligible',
  MaxCardIneligible = '/rbc/errors/max-card-ineligible',
  NeedsActionWarning = '/rbc/errors/needs-action-warning',
  OFACDenial = '/rbc/errors/ofac-denial',
  SanctionDenial = '/rbc/errors/sanctions-denial',
  OutstandingLoansIneligible = '/rbc/errors/outstanding-loans-ineligible',
  POBoxAddressIneligible = '/rbc/errors/po-box-address-ineligible',
  PreviousDenialIneligible = '/rbc/errors/previous-denial-ineligible',
  VerifiedEmail = '/rbc/errors/verified-email',
  FraudAlertDenial = '/rbc/errors/fraud-alert-denial',
  FraudDenial = '/rbc/errors/fraud-denial',
  CapacityRecheck = '/rbc/errors/capacity-recheck',
  Unknown = '/rbc/errors/unknown-error',
}

export const routingMachine = createMachine({
  id: 'router',
  predictableActionArguments: true,
  initial: 'initializing',
  tsTypes: {} as import('./rbcRoutingMachine.typegen').Typegen0,
  schema: {
    events: {} as RouteMachineServiceEvents,
  },
  on: {
    ...getRoutingMachineLevelEvents(Routes),
  },
  states: {
    initializing: {
      on: {
        SEND_RESET_ROUTER_STATE: {
          target: Routes.Intro,
        },
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: {
          target: Routes.Terms,
        },
        SEND_ROUTE_COMPLETE_IDENTITY_BUYER: {
          target: Routes.BillingAddress,
        },
      },
      always: [
        {
          cond: 'isUnauthenticatedInitial',
          target: Routes.Intro,
        },
      ],
      exit: ['removeLoadingIndicator'],
    },
    [Routes.Intro]: {
      meta: {
        analyticsPageName: PageNames.Intro,
        prefetchRoutes: [Routes.Email],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.Email,
        },
      },
    },
    [Routes.Email]: {
      meta: {
        analyticsPageName: PageNames.Email,
        prefetchRoutes: [Routes.Mobile],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.Mobile,
        },
        SEND_BACK: {
          target: Routes.Intro,
        },
      },
    },
    [Routes.Mobile]: {
      meta: {
        analyticsPageName: PageNames.Mobile,
        prefetchRoutes: [Routes.Verification],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.Verification,
        },
        SEND_BACK: {
          target: Routes.Email,
        },
      },
    },
    [Routes.Verification]: {
      meta: {
        analyticsPageName: PageNames.Verification,
        prefetchRoutes: [Routes.Eligibility, Routes.Terms],
      },
      on: {
        SEND_BACK: {
          target: Routes.Mobile,
        },
        SEND_ROUTE_NEW_BUYER: {
          target: Routes.Eligibility,
        },
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: {
          target: Routes.Terms,
        },
        SEND_ROUTE_AUTH_RETRY_WITH_PII: {
          target: Routes.LoginEligibility,
        },
        SEND_ROUTE_COMPLETE_IDENTITY_BUYER: {
          target: Routes.BillingAddress,
        },
      },
    },
    [Routes.Eligibility]: {
      meta: {
        analyticsPageName: PageNames.Eligibility,
        prefetchRoutes: [Routes.BillingAddress],
      },
      on: {
        SEND_ROUTE_COMPLETE_IDENTITY_BUYER: {
          target: Routes.BillingAddress,
        },
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: {
          target: Routes.Terms,
        },
      },
    },
    [Routes.LoginEligibility]: {
      meta: {
        analyticsPageName: PageNames.LoginEligibility,
        prefetchRoutes: [Routes.ConfirmEmail, Routes.BillingAddress],
      },
      on: {
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: [
          {
            cond: 'isAuthenticatedCompleteMismatchedBuyerPII',
            target: Routes.ConfirmEmail,
          },
          {
            target: Routes.Terms,
          },
        ],

        SEND_ROUTE_NEW_BUYER: {
          target: Routes.BillingAddress,
        },
        SEND_BACK: {
          target: Routes.Verification,
        },
      },
    },
    [Routes.ConfirmEmail]: {
      meta: {
        analyticsPageName: PageNames.ConfirmEmail,
        prefetchRoutes: [Routes.Terms],
      },
      on: {
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: {
          target: Routes.Terms,
        },
        SEND_ROUTE_APPROVED_COMPLETE_DOB_UPDATED_BUYER: {
          target: Routes.Terms,
        },
      },
    },
    [Routes.BillingAddress]: {
      meta: {
        analyticsPageName: PageNames.BillingAddress,
        prefetchRoutes: [Routes.Terms],
      },
      on: {
        SEND_BACK: {
          target: Routes.Eligibility,
        },
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: {
          target: Routes.Terms,
        },
      },
    },
    [Routes.Terms]: {
      meta: {
        analyticsPageName: PageNames.Terms,
        prefetchRoutes: [Routes.Employment],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.Employment,
        },
        SEND_ROUTE_WITH_CONDITION: {
          cond: 'isUnknownErrorRouteCondition',
          target: Routes.Unknown,
        },
      },
    },
    [Routes.Employment]: {
      meta: {
        analyticsPageName: PageNames.Employment,
        prefetchRoutes: [Routes.Review],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.Review,
        },
      },
    },
    [Routes.Review]: {
      meta: {
        analyticsPageName: PageNames.Review,
        prefetchRoutes: [Routes.LoanAgreement],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.LoanAgreement,
        },
        SEND_BACK: {
          target: Routes.Employment,
        },
      },
    },
    [Routes.LoanAgreement]: {
      meta: {
        analyticsPageName: PageNames.LoanAgreement,
        prefetchRoutes: [],
      },
      on: {
        SEND_BACK: {
          target: Routes.Review,
        },
      },
    },
    // Error Routes
    [Routes.AgeAlabamaMilitary]: {
      meta: {
        analyticsPageName: PageNames.AgeAlabamaMilitaryError,
        prefetchRoutes: [],
      },
    },
    [Routes.AgeIneligible]: {
      meta: {
        analyticsPageName: PageNames.BuyerAgeIneligibleError,
        prefetchRoutes: [],
      },
    },
    [Routes.BuyerHashFailed]: {
      meta: {
        analyticsPageName: PageNames.BuyerHashError,
        prefetchRoutes: [],
      },
      on: {
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: {
          target: Routes.Terms,
        },
      },
    },
    [Routes.BuyerSkippedInstallments]: {
      meta: {
        analyticsPageName: PageNames.BuyerSkippedInstallmentsError,
        prefetchRoutes: [],
      },
    },
    [Routes.BuyerStatusIneligible]: {
      meta: {
        analyticsPageName: PageNames.BuyerStatusIneligible,
        prefetchRoutes: [],
      },
    },
    [Routes.CreditDenial]: {
      meta: {
        analyticsPageName: PageNames.CreditDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.CreditFreeze]: {
      meta: {
        analyticsPageName: PageNames.CreditFreezeError,
        prefetchRoutes: [],
      },
    },
    [Routes.KycDenial]: {
      meta: {
        analyticsPageName: PageNames.KYCDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.LoanStatusIneligible]: {
      meta: {
        analyticsPageName: PageNames.LoanStatusIneligibleError,
        prefetchRoutes: [],
      },
    },
    [Routes.LocationIneligible]: {
      meta: {
        analyticsPageName: PageNames.LocationIneligibleError,
        prefetchRoutes: [],
      },
    },
    [Routes.MaxCardIneligible]: {
      meta: {
        analyticsPageName: PageNames.MaxCardIneligibleError,
        prefetchRoutes: [],
      },
    },
    [Routes.NeedsActionWarning]: {
      meta: {
        analyticsPageName: PageNames.NeedsActionWarningError,
        prefetchRoutes: [],
      },
    },
    [Routes.OFACDenial]: {
      meta: {
        analyticsPageName: PageNames.OFACError,
        prefetchRoutes: [],
      },
    },
    [Routes.OutstandingLoansIneligible]: {
      meta: {
        analyticsPageName: PageNames.OutstandingLoanIneligibleError,
        prefetchRoutes: [],
      },
    },
    [Routes.POBoxAddressIneligible]: {
      meta: {
        analyticsPageName: PageNames.POBoxAddressIneligibleError,
        prefetchRoutes: [],
      },
      on: {
        SEND_FORWARD: {
          target: Routes.BillingAddress,
        },
      },
    },
    [Routes.PreviousDenialIneligible]: {
      meta: {
        analyticsPageName: PageNames.PreviousDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.VerifiedEmail]: {
      meta: {
        analyticsPageName: PageNames.VerifiedEmailError,
        prefetchRoutes: [],
      },
    },
    [Routes.SanctionDenial]: {
      meta: {
        analyticsPageName: PageNames.SanctionDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.FraudAlertDenial]: {
      meta: {
        analyticsPageName: PageNames.FraudAlertDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.FraudDenial]: {
      meta: {
        analyticsPageName: PageNames.FraudDenialError,
        prefetchRoutes: [],
      },
    },
    [Routes.CapacityRecheck]: {
      meta: {
        analyticsPageName: PageNames.CapacityRecheckError,
        prefetchRoutes: [],
      },
      on: {
        SEND_ROUTE_APPROVED_COMPLETE_INITIAL_BUYER: {
          target: Routes.Terms,
        },
      },
    },
    [Routes.Unknown]: {
      meta: {
        analyticsPageName: PageNames.UnknownError,
        prefetchRoutes: [],
      },
    },
  },
});
