import _objectWithoutProperties from "/opt/bread/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _defineProperty from "/opt/bread/checkout/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var _excluded = ["capacity", "overflowCapacity"],
    _excluded2 = ["capacity"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var filterApplicationCapacity = function filterApplicationCapacity(app, buyer, canViewCapacity) {
  if (buyer !== null && buyer !== void 0 && buyer.doNotSellOrShare) {
    return {
      id: app === null || app === void 0 ? void 0 : app.id,
      buyerID: app === null || app === void 0 ? void 0 : app.buyerID,
      status: app === null || app === void 0 ? void 0 : app.status,
      optedOut: buyer === null || buyer === void 0 ? void 0 : buyer.doNotSellOrShare
    };
  }

  if (canViewCapacity) {
    return app;
  }

  var capacityToggleOff = _objectSpread(_objectSpread({}, app), {}, {
    offers: ((app === null || app === void 0 ? void 0 : app.offers) || []).map(function (offer) {
      var appOffer = _objectSpread({}, offer);

      var capacity = appOffer.capacity,
          overflowCapacity = appOffer.overflowCapacity,
          filteredOffer = _objectWithoutProperties(appOffer, _excluded);

      return filteredOffer;
    })
  });

  var capacity = capacityToggleOff.capacity,
      filteredApplication = _objectWithoutProperties(capacityToggleOff, _excluded2);

  return filteredApplication;
};