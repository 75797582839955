import { FunctionComponent, createContext, useMemo } from 'react';
import { useInterpret } from '@xstate/react';
import { InterpreterFrom } from 'xstate';
import { routingMachine } from '../lib/machines/cartRoutingMachine';
import { useHasReviewedApplication } from '../lib/hooks/useHasReviewedApplication';
import { useBuyerMachine } from './BuyerMachineContext';
import { useAuthentication } from './AuthenticationContext';
import { useApplicationMachine } from './ApplicationMachineContext';
import {
  RouteMachineContextProps,
  RouteMachineServiceProps,
  getDefaultApplicationErrorRouteGuards,
  getDefaultBuyerErrorRouteGuards,
  getLoadingIndicatorActions,
  getRoutePathConditionGuards,
  useSyncRouterStateEventEmitter,
  useBuyerApplicationEventEmitter,
  useApplicationMachineStateEventEmitter,
  useBuyerMachineStateEventEmitter,
  useAuthenticationMachineStateEventEmitter,
  useHandleStateRouteTransition,
  useRouteTransitionActions,
} from './RouteMachineService';
import { useLoadingManager } from './LoadingManager';
import { useFeatureFlags } from './FeatureFlagsContext';

type CartRoutingMachineService = InterpreterFrom<typeof routingMachine>;

export const CartRoutingMachineContext =
  createContext<RouteMachineContextProps<CartRoutingMachineService | null> | null>(
    null
  );

export const CartRoutingMachineProvider: FunctionComponent<RouteMachineServiceProps> =
  ({ children }) => {
    const { state: authState } = useAuthentication();
    const { state: buyerState } = useBuyerMachine();
    const {
      idVerification: { backImageData },
    } = useApplicationMachine();
    const { hasReviewedApplication } = useHasReviewedApplication();
    const { addLoader, removeLoader } = useLoadingManager();
    const { enableAlloyJourney } = useFeatureFlags();

    const routingMachineService = useInterpret(routingMachine, {
      guards: {
        isIDVerificationAlloy: () => enableAlloyJourney,
        hasReviewedApplication: () => {
          if (authState.matches('authenticated.anonymous')) {
            return true;
          }
          return hasReviewedApplication;
        },
        isUnauthenticatedInitial: () => {
          return authState.matches('unAuthenticated.initial');
        },
        isAuthenticatedCompleteMismatchedBuyerPII: () => {
          return authState.matches('authenticated.complete.mismatchedBuyerPII');
        },
        isBuyerReadyComplete: () => {
          return buyerState.matches('ready.complete');
        },
        isCartRetrieved: () => {
          return buyerState.matches('ready.cartRetrieved');
        },
        hasBackImageData: () => {
          return !!backImageData;
        },
        // Route path condition guards
        ...getRoutePathConditionGuards(),
        // Application Error condition routing guards
        ...getDefaultApplicationErrorRouteGuards(),
        // Buyer Error condition routing guards
        ...getDefaultBuyerErrorRouteGuards(),
      },
      actions: {
        ...getLoadingIndicatorActions({ addLoader, removeLoader }),
      },
    });

    // Handle state route transitions
    const { isTransitioning } = useHandleStateRouteTransition(
      routingMachineService
    );

    // Shared event emitters
    useSyncRouterStateEventEmitter(routingMachineService);
    useBuyerApplicationEventEmitter(routingMachineService);
    useApplicationMachineStateEventEmitter(routingMachineService);
    useBuyerMachineStateEventEmitter(routingMachineService);
    useAuthenticationMachineStateEventEmitter(routingMachineService);

    const { forward, back, routeWithCondition } = useRouteTransitionActions(
      routingMachineService
    );

    const value = useMemo(() => {
      return { service: routingMachineService, isTransitioning };
    }, [routingMachineService, isTransitioning]);

    return (
      <CartRoutingMachineContext.Provider value={value}>
        {children({
          isTransitioning,
          forward,
          back,
          routeWithCondition,
        })}
      </CartRoutingMachineContext.Provider>
    );
  };
