import { useCallback } from 'react';
import {
  validateOfferProductTypes,
  filterCustomerCloseDataCapacity,
} from '@ads-bread/shared/bread/util';
import {
  Application,
  FilteredCustomerCloseData,
  MerchantPaymentProduct,
  CustomerCloseDataCapacity,
  Offer,
} from '@ads-bread/shared/bread/codecs';
import { getFormAddress } from '../../components/pages/address/BillingAddress';
import {
  useMerchantPaymentProducts,
  useRawOrder,
  useSDKCallbacks,
  useSDKModalOptions,
  useMerchantDetails,
} from '../../components/XPropsContext';
import { groupOffersByType } from '../../lib/offers';
import { getMaxCapacities } from '../../lib/capacity';
import { useBuyerMachine } from '../../components/BuyerMachineContext';
import { useApplicationMachine } from '../../components/ApplicationMachineContext';
import { logger } from '../logger';

interface UseCloseModal {
  /** Closes the browser popup window that contains the Experience. */
  closeModal: (() => void) | null;
  /** Hides the Experience but retains the current state inside of it  */
  hideModal: (() => void) | null;
  /**
   * If the Experience is rendered in an iframe, calling this function will hide it.
   * If the Experience is rendered in a browser popup, calling this function will close it.
   */
  closeModalByContext: (() => void) | null;
}

/**
 * This hook returns functions that help you close the Experience modal
 */
export const useCloseModal = (): UseCloseModal => {
  const { merchantPaymentProducts } = useMerchantPaymentProducts();
  const { isDefaultOrder, order } = useRawOrder();
  const { onCustomerClose } = useSDKCallbacks();
  const { zoidHide, zoidClose, zoidContext } = useSDKModalOptions();
  const { application } = useApplicationMachine();
  const { canViewCapacity } = useMerchantDetails();
  const {
    buyer,
    email,
    phone,
    buyerName: name,
    billingAddress,
    shippingAddress,
  } = useBuyerMachine();

  const zoidCloseActionByContext =
    zoidContext === 'iframe' ? zoidHide : zoidClose;

  const getCustomerCloseData = useCallback((): FilteredCustomerCloseData => {
    if (buyer?.doNotSellOrShare) {
      return { optedOut: buyer?.doNotSellOrShare };
    }

    const primaryContact = buyer?.contacts?.[buyer?.primaryContactID];
    const buyerAddress = getFormAddress(
      primaryContact?.address,
      billingAddress,
      shippingAddress
    );
    const capacity = getCapacities(merchantPaymentProducts, application);

    const customerCloseData = {
      givenName: primaryContact?.name.givenName || name?.givenName,
      familyName: primaryContact?.name.familyName || name?.familyName,
      phone: primaryContact?.phone || phone || undefined,
      email: primaryContact?.email || email || undefined,
      address1: buyerAddress?.address1,
      address2: buyerAddress?.address2,
      locality: buyerAddress?.locality,
      region: buyerAddress?.region,
      postalCode: buyerAddress?.postalCode,
      country: buyerAddress?.country,
      order: !isDefaultOrder ? order : undefined,
      capacity,
      status: application?.status,
      optedOut: buyer?.doNotSellOrShare,
    };

    return filterCustomerCloseDataCapacity(customerCloseData, canViewCapacity);
  }, [
    application,
    billingAddress,
    buyer,
    email,
    isDefaultOrder,
    merchantPaymentProducts,
    name,
    order,
    phone,
    shippingAddress,
    canViewCapacity,
  ]);

  const closeModal = (): void => {
    // Remove after debugging info logs in production
    logger.info(
      { applicationID: application?.id, buyerID: buyer?.id },
      'closeModal called'
    );
    onCustomerClose?.(getCustomerCloseData());
    zoidClose?.();
  };

  const hideModal = () => {
    // Remove after debugging info logs in production
    logger.info(
      { applicationID: application?.id, buyerID: buyer?.id },
      'hideModal called'
    );
    onCustomerClose?.(getCustomerCloseData());
    zoidHide?.();
  };

  const closeModalByContext = () => {
    onCustomerClose?.(getCustomerCloseData());
    zoidCloseActionByContext?.();
  };

  return {
    closeModal: zoidClose ? closeModal : null,
    hideModal: zoidHide ? hideModal : null,
    closeModalByContext: zoidCloseActionByContext ? closeModalByContext : null,
  };
};

const getApprovedPaymentProducts = (
  offers: Offer[],
  merchantPaymentProducts: MerchantPaymentProduct[]
) => {
  const approvedPaymentProductIDs = offers.map(
    (offer) => offer.paymentProduct.id
  );
  return (
    merchantPaymentProducts?.filter((pp) =>
      approvedPaymentProductIDs.includes(pp.paymentProduct.id)
    ) || []
  );
};

const getCapacities = (
  merchantPaymentProducts: MerchantPaymentProduct[] = [],
  application: Application | null
): CustomerCloseDataCapacity | undefined => {
  const paymentAgreements = validateOfferProductTypes(
    application?.offers || [],
    merchantPaymentProducts
  );

  const approvedPaymentProducts = getApprovedPaymentProducts(
    paymentAgreements,
    merchantPaymentProducts
  );
  const paymentAgreementsByType = groupOffersByType(paymentAgreements);

  if (
    Object.keys(paymentAgreementsByType).length &&
    approvedPaymentProducts.length
  ) {
    const {
      INSTALLMENTS: installmentsMaxCapacity,
      SPLITPAY: splitPayMaxCapacity,
    } = getMaxCapacities(paymentAgreementsByType, approvedPaymentProducts);
    return {
      installmentsCapacity: installmentsMaxCapacity,
      splitPayCapacity: splitPayMaxCapacity,
    };
  }
};
