import { FunctionComponent, createContext, useMemo } from 'react';
import { useInterpret } from '@xstate/react';
import { InterpreterFrom } from 'xstate';
import { WalletStatus } from '@ads-bread/shared/bread/codecs';
import { routingMachine } from '../lib/machines/inStoreRoutingMachine';
import { useHasReviewedApplication } from '../lib/hooks/useHasReviewedApplication';
import { isTapToPayCompatibleDevice } from '../lib/user-agent';
import { useBuyerMachine } from './BuyerMachineContext';
import { useAuthentication } from './AuthenticationContext';
import { useApplicationMachine } from './ApplicationMachineContext';
import {
  RouteMachineContextProps,
  RouteMachineServiceProps,
  getDefaultApplicationErrorRouteGuards,
  getDefaultBuyerErrorRouteGuards,
  getLoadingIndicatorActions,
  getRoutePathConditionGuards,
  useSyncRouterStateEventEmitter,
  useBuyerApplicationEventEmitter,
  useApplicationMachineStateEventEmitter,
  useBuyerMachineStateEventEmitter,
  useAuthenticationMachineStateEventEmitter,
  useHandleStateRouteTransition,
  useRouteTransitionActions,
} from './RouteMachineService';
import { useIsLocationEnabled, useVirtualCard } from './XPropsContext';
import { useAppData } from './AppDataContext';
import { useLoadingManager } from './LoadingManager';
import { useFeatureFlags } from './FeatureFlagsContext';

const persistedSearchParams = ['programID', 'locationID', 'buyerID'];

type InStoreRoutingMachineService = InterpreterFrom<typeof routingMachine>;

export const InStoreRoutingMachineContext =
  createContext<RouteMachineContextProps<InStoreRoutingMachineService | null> | null>(
    null
  );

export const InStoreRoutingMachineProvider: FunctionComponent<RouteMachineServiceProps> =
  ({ children }) => {
    const {
      isVirtualCard,
      isMerchantKeyEntryCapable,
      isMerchantTapToPayCapable,
    } = useVirtualCard();
    const isLocationEnabled = useIsLocationEnabled();
    const { state: authState } = useAuthentication();
    const { state: buyerState, buyerID } = useBuyerMachine();
    const {
      state: applicationState,
      idVerification: { backImageData },
    } = useApplicationMachine();
    const {
      data: { issuance },
    } = useAppData();
    const { hasReviewedApplication } = useHasReviewedApplication();
    const { addLoader, removeLoader } = useLoadingManager();
    const { enableAlloyJourney } = useFeatureFlags();

    const routingMachineService = useInterpret(routingMachine, {
      guards: {
        isIDVerificationAlloy: () => enableAlloyJourney,
        hasReviewedApplication: () => {
          if (authState.matches('authenticated.anonymous')) {
            return true;
          }
          return hasReviewedApplication;
        },
        isExchangedBuyerWithReviewedApplication: () => {
          if (authState.matches('authenticated.anonymous')) {
            return true;
          }
          return hasReviewedApplication && !!buyerID;
        },
        isExchangedBuyer: () => {
          return !!buyerID;
        },
        isUnauthenticatedInitial: () => {
          return authState.matches('unAuthenticated.initial');
        },
        isAuthenticatedCompleteMismatchedBuyerPII: () => {
          return authState.matches('authenticated.complete.mismatchedBuyerPII');
        },
        isBuyerReadyComplete: () => {
          return buyerState.matches('ready.complete');
        },
        hasBackImageData: () => {
          return !!backImageData;
        },
        isSplitPaySelected: () => {
          return applicationState.matches('ready.approved.selected.splitPay');
        },
        isVirtualCardWithActiveWallet: () => {
          if (!isVirtualCard) {
            return false;
          }
          return !!issuance?.wallets?.some(
            (w) => w.status === WalletStatus.ACTIVE
          );
        },
        isVirtualCardWithTerminatedWallet: () => {
          if (!isVirtualCard) {
            return false;
          }
          return !!issuance?.wallets?.some(
            (w) =>
              w.status === WalletStatus.TERMINATED &&
              w.error === true &&
              !isMerchantKeyEntryCapable
          );
        },
        isLocationDisabled: () => {
          return !isLocationEnabled;
        },
        isVirtualCardApprovedApplication: () => {
          return applicationState.matches('ready.approved.inStore.virtualCard');
        },
        isVirtualCardInvalidConfig: () => {
          if (!isVirtualCard) {
            return false;
          }
          return !isMerchantTapToPayCapable && !isMerchantKeyEntryCapable;
        },
        isVirtualCardUnsupportedDevice: () => {
          if (!isVirtualCard) {
            return false;
          }
          return !isMerchantKeyEntryCapable && !isTapToPayCompatibleDevice;
        },
        isPreparedInStoreApplication: () => {
          return applicationState.matches('ready.approved.inStore.prepared');
        },
        // Route path condition guards
        ...getRoutePathConditionGuards(),
        // Application Error condition routing guards
        ...getDefaultApplicationErrorRouteGuards(),
        // Buyer Error condition routing guards
        ...getDefaultBuyerErrorRouteGuards(),
      },
      actions: {
        ...getLoadingIndicatorActions({ addLoader, removeLoader }),
      },
    });

    // Handle state route transitions
    const { isTransitioning } = useHandleStateRouteTransition(
      routingMachineService,
      persistedSearchParams
    );

    // Shared event emitters
    useSyncRouterStateEventEmitter(routingMachineService);
    useBuyerApplicationEventEmitter(routingMachineService);
    useApplicationMachineStateEventEmitter(routingMachineService);
    useBuyerMachineStateEventEmitter(routingMachineService);
    useAuthenticationMachineStateEventEmitter(routingMachineService);

    const { forward, back, routeWithCondition } = useRouteTransitionActions(
      routingMachineService
    );

    const value = useMemo(() => {
      return { service: routingMachineService, isTransitioning };
    }, [routingMachineService, isTransitioning]);

    return (
      <InStoreRoutingMachineContext.Provider value={value}>
        {children({
          isTransitioning,
          forward,
          back,
          routeWithCondition,
        })}
      </InStoreRoutingMachineContext.Provider>
    );
  };
